.basic-application-container {
	display: flex;
	flex-direction: column;
	height: 66vh;
	width: 66vw;
	min-width: 33vw;
	min-height: 33vh;
	border-radius: 10px;
	position: fixed;
	box-shadow: 2.5px 1px 1px rgba(0, 0, 0, 0.12),
		2.5px 2px 2px rgba(0, 0, 0, 0.12), 2.5px 4px 4px rgba(0, 0, 0, 0.12),
		2.5px 8px 10px rgba(0, 0, 0, 0.12), 2.5px 16px 18px rgba(0, 0, 0, 0.12);
}

.basic-application-topbar {
	display: flex;
	justify-content: space-between;
	background-color: white;
	border-radius: 10px 10px 0 0;
}

.basic-application-topbar-sub-container {
	display: flex;
}

.basic-application-button,
.basic-application-exit {
	padding: 0.5em;
	min-width: 40px;
	text-align: center;
}

.basic-application-button:hover {
	background-color: lightgray;
	cursor: default;
}

.basic-application-exit:hover {
	background-color: red;
	border-radius: 0 10px 0 0;
	cursor: default;
}

.hide-app {
	visibility: hidden;
}

.basic-application-icon {
	height: 20px;
	width: 20px;
}

.basic-application-icon-container,
.basic-application-title {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	cursor: default;
}

.basic-application-icon-container {
	width: 40px;
}
