.githubmain-container {
    display: grid;
    grid-template-columns: 1fr 2fr 3fr 1fr;
}

.githubmain-container-mini {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 50px;
}