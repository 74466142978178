.picro-center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;  
    left: 50%; 
    transform: translate(-50%, -50%);
}

.picro-container {
    height: 24vh;
    width: 24vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
}

.picro-square {
    height: 10vh;
    width: 10vh;
    background-color: white;
}