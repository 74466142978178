.tab-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 10vw;
    height: 100%;
    cursor: pointer;
}

.tab-title-container {
    display: flex;
    gap: 10px;
}

.tab-close-hide {
    visibility: hidden;
}