.desktop-container {
	background-image: url("https://boring-visvesvaraya-9b6f66.netlify.app/www/genericBackground.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-rows: 15fr 1fr;
}

.desktop-top-fixer {
	min-height: 93%;
	width: 100%;
}
