.rightblob-container {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: white;
    border: 1px solid lightgray;
    height: max-content;
    gap: 15px;
    padding: 10px;
}

.rightblob-title {
    font-weight: bold;
}

.rightblob-posts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}