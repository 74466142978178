.keyword-purple {
	color: rgb(201, 136, 234);
}

.keyword-green {
	color: rgb(195, 232, 141);
}

.keyword-cyan {
	color: rgb(128, 216, 255);
}

.keyword-blue {
	color: blue;
}

.keyword-red {
	color: rgb(245, 115, 89);
}

.keyword-orange {
	color: orange;
}

.keyword-yellow {
	color: gold;
}

.indent {
	width: 1em;
}

.coloredkeyword-container {
	display: inline-flex;
	cursor: text;
}

.coloredkeyword-container:focus {
	background-color: var(--color-bg);
}

.space {
	width: .4em;
}