.bscode-main-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
	.bscode-main-container {
		min-width: max-content;
	}
}
