.persistanttopbar-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	border-bottom: 1px solid rgb(148, 148, 148);
	min-height: 10%;
	padding-top: 10px;
	overflow: hidden;
	color:rgb(148, 148, 148);
}

.persistanttopbar-choices {
	display: flex;
	justify-content: space-around;
	gap: 10px;
	align-items: center;
}
