.bscode-explorer-component-container {
	display: flex;
	cursor: pointer;
	gap: 10px;
	padding: 5px 2px;
}

.bscode-explorer-highlight {
	border: 1px solid var(--color-g);
}

.highlight-title {
	border: 1px solid var(--color-lg);
}

.bscode-explorer-highlight:focus {
	border: 1px solid var(--color-lb);
	background-color: var(--color-lg-t);
}

.highlight-file:focus {
	border: 1px solid var(--color-lb);
	background-color: var(--color-lb-t);
}

.bscode-explorer-expander {
	overflow: scroll;
}

.explorer-option-title {
	background-color: var(--color-lg);
	color: white;
}
