.typeline {
    height: 1em;
    width: 1px;
	background-color: white;
	animation: blink-animation 1s steps(5, start) infinite;
	-webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
@-webkit-keyframes blink-animation {
	to {
		visibility: hidden;
	}
}
