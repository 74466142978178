.linkedin-container, .linkedin-container-mini {
	overflow-y: scroll;
	background-color: whitesmoke;
	border-radius: 0 0 10px 10px;
	height: 100%;
}

.linkedin-container {
	display: grid;
	grid-template-columns: 1fr 2fr 4fr 2fr 1fr;
	gap: 10px;
}

.linkedin-container-mini {
	display: flex;
	flex-direction: column;
	padding: 20px;
	gap: 10px;
}

.linkedin-posts {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.linkedin-basic {
	background-color: white;
	border-radius: 10px;
	border: 1px solid lightgray;
}
