.bscode-container {
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	color: white;
	background-color: var(--color-g);
}

.bscode-main-area {
	display: grid;
	height: 93.75vh;
	grid-template-columns: 4.5em 2fr 9fr;
}
