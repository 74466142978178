.terminal-input-container {
	display: flex;
}

.terminal-dollar {
	padding-right: 10px;
}

.terminal-input-indicator {
	height: 1em;
	width: 8px;
	border: 1px solid white;
}

.indicator-on {
	background-color: white;
}

.terminal-input-container:focus {
	outline: none;
}
