.bscode-explorer-container {
	height: 93.75vh;
}

.bscode-explorer-options {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.bscode-explorer-title {
	display: flex;
	justify-content: space-between;
	padding: 1em;
}

.bscode-explorer-flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}
