.gitCrash-container {
	background: black;
	height: 100%;
	overflow:hidden;
	border-radius: 0 0 10px 10px;
    border: 1px solid lightgray;
}

.white {
	color: white;
	font-size: 50px;
}