.bscode-terminal-container {
	border-top: 1px solid var(--color-lg);
	min-height: 30vh;
	width: 100%;
}

.bscode-font {
	font-family: "Courier Prime", monospace;
	font-weight: bold;
	padding: 30px;
}
