.topbarchoice-container {
	display: flex;
	gap: 10px;
	cursor: pointer;
	border-bottom: 2px solid rgba(0, 0, 0, 0);
	height: 100%;
	align-items: center;
}

.topbarchoice-container:hover {
	border-bottom: 2px solid rgb(148, 148, 148);
}

.topbarchoice-container:focus {
	border-bottom: 2px solid lightsalmon;
	font-weight: bold;
}

.reponumber {
	border-radius: 25%;
	background-color: rgb(107, 107, 107);
	padding: 0 2px;
}
